 @font-face {
   font-family: "DM sans";
   src: url("https://static.staticdj.com/oss/operation/3e7f038b85daa739336e4a3476c687f2.ttf") format("truetype");
   font-display: swap;
 }

 @font-face {
   font-family: "PP Right Grotesk Narrow Black";
   src: url("https://static.staticdj.com/oss/operation/bc12a41ea4ac3afe67e32fc9e0c36c58.woff2") format("woff2");
   font-display: swap;
 }

 @font-face {
   font-family: "PP Right Grotesk Wide Regular";
   src: url("https://static.staticdj.com/oss/operation/960abc5f7075d7c0e699c28b70303531.woff2") format("woff2");
   font-display: swap;
 }

 body {
   font-family: DM sans;
 }

@tailwind base;
@tailwind components;
@tailwind utilities;

.body-text{
  line-height: 1.6;
}
.body-text table{
  border:1px solid #e2e8f0;
  border-width: 1px 0 0 1px;
  border-collapse: collapse;
}
.body-text table td,.body-text table th{
  padding:4px;
  border:1px solid #e2e8f0;
  border-width: 0 1px 1px 0;
}