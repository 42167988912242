@font-face {
  font-family: DM sans;
  src: url("https://static.staticdj.com/oss/operation/3e7f038b85daa739336e4a3476c687f2.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: PP Right Grotesk Narrow Black;
  src: url("https://static.staticdj.com/oss/operation/bc12a41ea4ac3afe67e32fc9e0c36c58.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: PP Right Grotesk Wide Regular;
  src: url("https://static.staticdj.com/oss/operation/960abc5f7075d7c0e699c28b70303531.woff2") format("woff2");
  font-display: swap;
}

body {
  font-family: DM sans;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.visible {
  visibility: visible;
}

.absolute {
  position: absolute;
}

.sticky {
  position: sticky;
}

.left-0 {
  left: 0;
}

.top-0 {
  top: 0;
}

.top-20 {
  top: 5rem;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.m-0 {
  margin: 0;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mt-5 {
  margin-top: 1.25rem;
}

.box-border {
  box-sizing: border-box;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.hidden {
  display: none;
}

.h-4 {
  height: 1rem;
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.w-4 {
  width: 1rem;
}

.w-full {
  width: 100%;
}

.max-w-container {
  max-width: 1120px;
}

.list-none {
  list-style-type: none;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
}

.divide-solid > :not([hidden]) ~ :not([hidden]) {
  border-style: solid;
}

.divide-white > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-divide-opacity));
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.rounded-\[3px\] {
  border-radius: 3px;
}

.bg-\[\#C7100F\] {
  --tw-bg-opacity: 1;
  background-color: rgb(199 16 15 / var(--tw-bg-opacity));
}

.bg-back {
  background-color: #f2eada;
}

.bg-button {
  --tw-bg-opacity: 1;
  background-color: rgb(48 176 121 / var(--tw-bg-opacity));
}

.bg-grayBack {
  background-color: #f8f8f8;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-banner {
  background-image: url("https://img.staticdj.com/oss/operation/86d2d8f1d2c4418d55dd04cf9b5943bc.webp");
}

.bg-cover {
  background-size: cover;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.text-\[\#545454\] {
  --tw-text-opacity: 1;
  color: rgb(84 84 84 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.shadow-header {
  --tw-shadow: 0 5px 10px #9aa1b11a;
  --tw-shadow-colored: 0 5px 10px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.drop-shadow-header {
  --tw-drop-shadow: drop-shadow(drop-shadow(0 2px 1px #0000000d) drop-shadow(0 1px 3px #0000001a));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.body-text {
  line-height: 1.6;
}

.body-text table {
  border-collapse: collapse;
  border: 0 solid #e2e8f0;
  border-width: 1px 0 0 1px;
}

.body-text table td, .body-text table th {
  border: 1px solid #e2e8f0;
  border-width: 0 1px 1px 0;
  padding: 4px;
}

@media (width <= 540px) {
  .sm\:block {
    display: block;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:flex-wrap {
    flex-wrap: wrap;
  }

  .sm\:justify-center {
    justify-content: center;
  }

  .sm\:gap-y-5 {
    row-gap: 1.25rem;
  }

  .sm\:break-words {
    overflow-wrap: break-word;
  }
}

.\[\&\:checked\+\.close\]\:hidden:checked + .close {
  display: none;
}

.\[\&\:checked\~\.menulist\]\:block:checked ~ .menulist, .\[\&\:checked\~\.open\]\:block:checked ~ .open {
  display: block;
}

.\[\&\>\*\]\:px-4 > * {
  padding-left: 1rem;
  padding-right: 1rem;
}

.\[\&_a\]\:mx-8 a {
  margin-left: 2rem;
  margin-right: 2rem;
}

.\[\&_a\]\:my-2 a {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.\[\&_a\]\:text-primary a {
  --tw-text-opacity: 1;
  color: rgb(34 34 34 / var(--tw-text-opacity));
}

.\[\&_a\]\:text-white a {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.\[\&_a\]\:no-underline a {
  text-decoration-line: none;
}

.\[\&_h2\]\:font-grotesk h2 {
  font-family: PP Right Grotesk Wide Regular;
}

.\[\&_h2\]\:font-extrabold h2 {
  font-weight: 800;
}

.\[\&_h2\]\:text-primary h2 {
  --tw-text-opacity: 1;
  color: rgb(34 34 34 / var(--tw-text-opacity));
}

.\[\&_img\]\:w-full img {
  width: 100%;
}

.\[\&_li\]\:cursor-pointer li {
  cursor: pointer;
}

.\[\&_ol\]\:pl-0 ol {
  padding-left: 0;
}

.\[\&_ol\]\:pl-5 ol {
  padding-left: 1.25rem;
}

.\[\&_strong\]\:font-grotesk strong {
  font-family: PP Right Grotesk Wide Regular;
}

.\[\&_strong\]\:font-extrabold strong {
  font-weight: 800;
}

.\[\&_strong\]\:text-primary strong {
  --tw-text-opacity: 1;
  color: rgb(34 34 34 / var(--tw-text-opacity));
}

/*# sourceMappingURL=index.26732b39.css.map */
